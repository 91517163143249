import algoliasearch from "algoliasearch";
import axios from "axios";
import _, { map, size } from "lodash";
import moment from "moment";
import {
  REACT_APP_ALGOLIA_API_KEY,
  REACT_APP_ALGOLIA_APPLICATION_ID,
} from "../../../helpers/const";

// ALGOLIA
const algoliaSearch = async (data: any): Promise<any> => {
  let query = data?.inputChange;
  let str = "";
  let exchangeCondition = "";
  let isSourceTixstock: any = "";
  let coreLayout = data?.coreLayout?.allowedAccess;
  let checkExchange =
    coreLayout?.is_connected_to_preferred_partners === 0
      ? coreLayout?.connected_exchanges &&
        Array.isArray(coreLayout?.connected_exchanges) &&
        _.size(coreLayout?.connected_exchanges) > 0
        ? coreLayout?.connected_exchanges
        : ""
      : "";

  // IF LOGGEDIN USERD IS NOT CONNECTED WITH TIXSTOCK THEN PASS CONNECTED MARKETPLACE IDS
  checkExchange &&
    checkExchange.map((ex: number) => (str += "exchanges=" + ex + " OR "));
  if (checkExchange) {
    exchangeCondition = `${str.slice(0, -3)}`;
  }

  // IF LOGGED USER IS CONNECTED WITH TIXSTOCK THEN PASS BELOW FILTER
  if (!checkExchange) {
    isSourceTixstock = ` is_source_tixstock=1 `;
  }

  // Get today's date and format it as YYYY-MM-DD
  const today = new Date().toISOString().slice(0, 10);
  // Create Algolia filters to retrieve data only for today and future dates
  let dateFilters = `AND event_date_local >= ${Date.parse(today) / 1000}`;

  // Connect and authenticate with your Algolia app
  const client = algoliasearch(
    REACT_APP_ALGOLIA_APPLICATION_ID,
    REACT_APP_ALGOLIA_API_KEY
  );

  // SEARCH WITH EVENTS
  const index = client.initIndex("events");

  // EXECUTE THE API WITH PAYLOAD
  const response = await index.search(query, {
    filters: `${exchangeCondition}  ${isSourceTixstock} ${dateFilters}`,
  });
  return response;
};

const getOrderListingPayload = (data: any) => {
  let event_id = _.map(data?.event_id, "id");
  const teamValue: any = data?.teams?.length > 0 && _.map(data?.teams, "id");
  const order_ids: any = data?.order_ids?.length > 0 && _.map(data?.order_ids);

  const marketplace_id: any = data?.marketplace?.id && [data?.marketplace?.id];

  const order_start_date: any =
    data?.transcation_date?.startDate &&
    moment(data?.transcation_date?.startDate).format("YYYY-MM-DD");
  const order_end_date: any =
    data?.transcation_date?.endDate &&
    moment(data?.transcation_date?.endDate).format("YYYY-MM-DD");

  const event_start_date: any =
    data?.event_date?.startDate &&
    moment(data?.event_date?.startDate).format("YYYY-MM-DD");

  const event_end_date: any =
    data?.event_date?.endDate &&
    moment(data?.event_date?.endDate).format("YYYY-MM-DD");

  const order_statuses: any =
    data?.order_status && map(data?.order_status, "id");

  const internal_order_statuses: any =
    data?.internal_order_status?.length > 0 &&
    _.map(data?.internal_order_status, "id");

  const is_paid: any =
    data?.payment_status?.length > 0 && _.map(data?.payment_status, "value");

  let marketplace_currency = data?.marketplace_currency?.id;

  const store_id: any = data?.store_id;

  const marketplace_status: any = data?.marketplaces_payment_status?.value;

  let order_by = data?.order_by;
  let sort_order = data?.sort_order;

  let get_teams = data?.get_teams;
  let exclude_order_ids = data?.exclude_order_ids;
  let invoice_number = data?.invoice_number;
  let reference = data?.reference;
  let invoice_id = data?.invoice_id;

  let payload = {
    ...(_.size(teamValue) > 0 && {
      team_id: teamValue,
    }),
    ...(_.size(marketplace_id) > 0 && {
      marketplace_id: marketplace_id,
    }),
    ...(order_start_date && {
      order_start_date: order_start_date,
    }),
    ...(event_id && size(event_id) > 0 && { event_id }),
    ...(data?.query && { keywords: data?.query }),
    ...(order_end_date && {
      order_end_date: order_end_date,
    }),
    ...(event_start_date && {
      event_start_date: event_start_date,
    }),

    ...(event_end_date && {
      event_end_date: event_end_date,
    }),
    ...(order_statuses &&
      size(order_statuses) > 0 && {
        order_statuses: order_statuses,
      }),
    ...(_.size(internal_order_statuses) > 0 && {
      internal_order_statuses: internal_order_statuses,
    }),
    ...(_.size(is_paid) > 0 && {
      is_paid: is_paid,
    }),
    ...(store_id && {
      store_id: store_id,
    }),
    ...(marketplace_status && {
      marketplace_status: marketplace_status,
    }),
    ...(data?.page && { page: data?.page }),
    ...(data?.per_page && {
      per_page: data?.per_page,
    }),
    ...(sort_order && order_by && { order_by }),
    ...(sort_order && order_by && { sort_order }),
    ...(data?.export_to_csv === 1 && { export_to_csv: 1 }),
    ...(get_teams && { get_teams: 1 }),
    ...(exclude_order_ids &&
      size(exclude_order_ids) > 0 && { exclude_order_ids }),
    ...(invoice_number && { invoice_number }),
    ...(reference && { reference }),
    ...(marketplace_currency && { marketplace_currency }),
    ...(invoice_id && { invoice_id }),
    ...(order_ids && { order_ids }),
  };

  return payload;
};

// Order Listing API
const getTXAccountOrdersListings = async (data: any): Promise<any> => {
  let payload = getOrderListingPayload({
    ...data?.orderFilters,
    exclude_order_ids: data?.exclude_order_ids,
    get_teams: data?.get_teams,
    ...(data?.export_to_csv && { export_to_csv: 1 }),
    ...(data?.invoice_id && { invoice_id: data?.invoice_id }),
  });
  const response = await axios.post(`tx-accounts/orders`, payload);
  return response.data;
};

// INVOICE LISTING API
const getTXAccountInvoiceListings = async (data: any): Promise<any> => {
  let marketplace_name = data?.invoiceFilters?.marketplace;
  let statuses = data?.invoiceFilters?.invoice_status;

  let payment_start_date: any =
    data?.invoiceFilters?.payment_date?.startDate &&
    moment(data?.invoiceFilters?.payment_date?.startDate).format("YYYY-MM-DD");

  let payment_end_date: any =
    data?.invoiceFilters?.payment_date?.endDate &&
    moment(data?.invoiceFilters?.payment_date?.endDate).format("YYYY-MM-DD");

  let invoice_start_date: any =
    data?.invoiceFilters?.invoices_date?.startDate &&
    moment(data?.invoiceFilters?.invoices_date?.startDate).format("YYYY-MM-DD");

  let invoice_end_date: any =
    data?.invoiceFilters?.invoices_date?.endDate &&
    moment(data?.invoiceFilters?.invoices_date?.endDate).format("YYYY-MM-DD");

  let invoice_number = data?.invoiceFilters?.query;
  let order_by = data?.invoiceFilters?.order_by;
  let sort_order = data?.invoiceFilters?.sort_order;

  // PAYLOAD
  const payload = {
    ...(size(marketplace_name) > 0 && {
      marketplace_id: _.map(marketplace_name, "id"),
    }),
    ...(size(statuses) > 0 && { statuses: _.map(statuses, "id") }),
    ...(payment_end_date && { payment_start_date, payment_end_date }),
    ...(invoice_end_date && { invoice_start_date, invoice_end_date }),
    ...(invoice_number && { invoice_number }),
    ...(data?.invoiceFilters?.page && { page: data?.invoiceFilters?.page }),
    ...(data?.invoiceFilters?.per_page && {
      per_page: data?.invoiceFilters?.per_page,
    }),
    ...(sort_order && order_by && { order_by }),
    ...(sort_order && order_by && { sort_order }),
  };

  const response = await axios.post(`tx-accounts/invoices`, payload);
  return response.data;
};

// SAVE ORDER INVOICE
const saveOrderInvoice = async (data: any): Promise<any> => {
  let payload = getOrderListingPayload(data);
  // Remove 'page' and 'per_page' keys
  delete payload.page;
  delete payload.per_page;
  const response = await axios.post(`tx-accounts/save-invoice`, payload);
  return response.data;
};

// REMOVE ORDER INVOICE
const removeOrderInvoice = async (data: any): Promise<any> => {
  const response = await axios.post(`tx-accounts/remove-invoice-orders`, data);
  return response.data;
};

// ORDER WALLET BALANCE
const ordersWalletBalance = async (data: any): Promise<any> => {
  let payload = getOrderListingPayload(data?.orderFilters);
  payload = {
    ...payload,
    is_tx_account: true,
  };
  delete payload.page;
  delete payload.per_page;

  const response = await axios.post(`/reports/counts`, payload);
  return response.data;
};

// INVOICE VIEW API
const invoiceViewAPI = async (data: any): Promise<any> => {
  const payload = {
    page: data?.page,
    per_page: data?.per_page,
    ...(data?.order_by && { order_by: data?.order_by }),
    ...(data?.order_by && { sort_order: data?.sort_order }),
  };
  const response = await axios.post(
    `tx-accounts/invoices/view/${data?.id}`,
    payload
  );
  return response.data;
};

// DOWNLOAD PDF
const downloadPDF = async (data: any): Promise<any> => {
  let paylod = {
    invoice_id: typeof data === "string" ? [data] : [...data],
  };
  const response = await axios.post(`tx-accounts/invoice/download`, paylod, {
    responseType: "blob",
  });
  return response.data;
};

// GET TEAM COUNT
const getTeamCount = async (data: any): Promise<any> => {
  let payload = {
    get_teams: 1,
    ...(data && size(data) > 0 && { exclude_order_ids: data }),
  };
  const response = await axios.post(`tx-accounts/orders`, payload);
  return response.data;
};

// PAYOUT LISTING API
const getTXAccountPayoutListings = async (data: any): Promise<any> => {
  const date_from: any =
    data?.payOutFilters?.pay_out_date?.startDate &&
    moment(data?.payOutFilters?.pay_out_date?.startDate).format("YYYY-MM-DD");

  const date_to: any =
    data?.payOutFilters?.pay_out_date?.endDate &&
    moment(data?.payOutFilters?.pay_out_date?.endDate).format("YYYY-MM-DD");

  const team_id: any =
    data?.payOutFilters?.teams?.length > 0 &&
    _.map(data?.payOutFilters?.teams, "id");
  let order_by = data?.payOutFilters?.order_by;
  let sort_order = data?.payOutFilters?.sort_order;
  let id: any = data?.id;

  const payload = {
    ...(date_to && { date_to }),
    ...(date_from && { date_from }),
    ...(team_id && { team_id }),
    ...(data?.payOutFilters?.page && { page: data?.payOutFilters?.page }),
    ...(data?.payOutFilters?.per_page && {
      per_page: data?.payOutFilters?.per_page,
    }),
    ...(sort_order && order_by && { order_by }),
    ...(sort_order && { sort_order }),
    ...(id && { id }),
  };
  const response = await axios.post(`tx-accounts/payouts`, payload);
  return response.data;
};

const makePayout = async (data: any): Promise<any> => {
  let payload = getOrderListingPayload({
    ...data?.listing_data?.filterData?.otherFilters,
    exclude_order_ids: data?.excludesOrderId,
  });
  // Remove 'page' and 'per_page' keys
  delete payload.page;
  delete payload.per_page;
  delete payload.sort_order;
  delete payload.order_by;
  const response = await axios.post(`tx-accounts/make-payouts`, payload);
  return response.data;
};

// SELLER WALLET
const getTXAccountSellerWalletListings = async (data: any): Promise<any> => {
  let team_id =
    data?.sellerWalletsFilter?.teams &&
    _.map(data?.sellerWalletsFilter?.teams, "id");
  let statuses =
    data?.sellerWalletsFilter?.statuses &&
    _.map(data?.sellerWalletsFilter?.statuses, "id");
  let sort_order = data?.sellerWalletsFilter?.sort_order;
  let order_by = data?.sellerWalletsFilter?.order_by;
  let page = data?.sellerWalletsFilter?.page;
  let per_page = data?.sellerWalletsFilter?.per_page;

  let payload = {
    ...(size(team_id) > 0 && {
      team_id,
    }),
    ...(size(statuses) > 0 && {
      statuses,
    }),
    ...(sort_order && order_by && { sort_order }),
    ...(sort_order && sort_order && { order_by }),
    page,
    per_page,
  };
  const response = await axios.post(`tx-accounts/seller-wallets`, payload);
  return response.data;
};

const getTXAccountBankAccountListings = async (data: any): Promise<any> => {
  let team_id =
    data?.bankAccountFilter?.teams &&
    _.map(data?.bankAccountFilter?.teams, "id");
  let sort_order = data?.bankAccountFilter?.sort_order;
  let order_by = data?.bankAccountFilter?.order_by;
  let page = data?.bankAccountFilter?.page;
  let per_page = data?.bankAccountFilter?.per_page;

  let payload = {
    ...(size(team_id) > 0 && {
      team_id,
    }),
    ...(sort_order && order_by && { sort_order }),
    ...(sort_order && sort_order && { order_by }),
    page,
    per_page,
  };
  const response = await axios.post(`tx-accounts/bank-accounts`, payload);
  return response.data;
};

// WITHDRAW REQUEST
const getTXAccountWithdrawRequestListings = async (data: any): Promise<any> => {
  let team_id =
    data?.withdrawRequestFilter?.teams &&
    _.map(data?.withdrawRequestFilter?.teams, "id");
  let statuses =
    data?.withdrawRequestFilter?.statuses &&
    _.map(data?.withdrawRequestFilter?.statuses, "id");
  let sort_order = data?.withdrawRequestFilter?.sort_order;
  let order_by = data?.withdrawRequestFilter?.order_by;
  let page = data?.withdrawRequestFilter?.page;
  let per_page = data?.withdrawRequestFilter?.per_page;
  let from_request_date: any =
    data?.withdrawRequestFilter?.requestedDate?.startDate &&
    moment(data?.withdrawRequestFilter?.requestedDate?.startDate).format(
      "YYYY-MM-DD"
    );

  let to_request_date: any =
    data?.withdrawRequestFilter?.requestedDate?.endDate &&
    moment(data?.withdrawRequestFilter?.requestedDate?.endDate).format(
      "YYYY-MM-DD"
    );

  let payload = {
    ...(size(team_id) > 0 && {
      team_id,
    }),
    ...(size(statuses) > 0 && {
      statuses,
    }),
    ...(sort_order && order_by && { sort_order }),
    ...(sort_order && sort_order && { order_by }),
    ...(from_request_date && to_request_date && { from_request_date }),
    ...(to_request_date && from_request_date && { to_request_date }),
    page,
    per_page,
  };
  const response = await axios.post(`tx-accounts/withdraw-requests`, payload);
  return response.data;
};

// REFRESH WALLET
const addBankWallet = async (data: any, id: any): Promise<any> => {
  let payload = {
    action: data,
  };
  const response = await axios.get(`tx-accounts/add-seller-wallet/${id}`, {
    params: payload,
  });
  return response.data;
};

// ADD BANK ACCOUNT
const addBankAccount = async (data: any): Promise<any> => {
  let payload = {
    ...data,
  };
  const response = await axios.post(`tx-accounts/add-bank-account`, payload);
  return response.data;
};

// APPROVE REQUEST
const approveWithdrawalRequest = async (data: any): Promise<any> => {
  const response = await axios.get(`tx-accounts/process-withdrawal`, {
    params: { withdraw_request_id: data },
  });
  return response.data;
};

export {
  addBankAccount,
  addBankWallet,
  algoliaSearch,
  approveWithdrawalRequest,
  downloadPDF,
  getTeamCount,
  getTXAccountBankAccountListings,
  getTXAccountInvoiceListings,
  getTXAccountOrdersListings,
  getTXAccountPayoutListings,
  getTXAccountSellerWalletListings,
  getTXAccountWithdrawRequestListings,
  invoiceViewAPI,
  makePayout,
  ordersWalletBalance,
  removeOrderInvoice,
  saveOrderInvoice,
};
